<script>
import penaltyApi from '@/api/penalty'
import ModalPay from "@/views/penalties/components/ModalPay.vue";
import receipt from "@/views/penalties/mixins/receipt";

export default {
    name: "show",
    components: {ModalPay},
    mixins: [receipt],
    data() {
        return {
            penalty: null,
        }
    },
    methods: {
        getPenalty() {
            if (/^\d+$/.test(this.$route.params.id)) {
                penaltyApi.getPenalty(this.$route.params.id).then(response => {
                    this.penalty = response.data
                }).catch(error => {
                    if (error.response.status === 404) {
                        this.$router.push({name: 'penalties'})
                    }
                })
            } else if (/^n\d+$/.test(this.$route.params.id)) {
                penaltyApi.getPenaltyForClient(this.$route.params.id.slice(1)).then(response => {
                    this.penalty = response.data
                }).catch(error => {
                    if (error.response.status === 404) {
                        this.$router.push({name: 'penalties'})
                    }
                })
            } else {
                this.$router.push({name: 'penalties'})
            }
        }
    },
    mounted() {
        this.getPenalty()
    }
}
</script>

<template>
    <div class="penalty">
        <div class="col-xl-2 col-md-6 pr-0">
            <router-link :to="{ name: 'penalties'}" class="go-back content-">
                Повернутися
            </router-link>
        </div>
        <div v-if="penalty">
            <el-row type="flex" justify="middle">
                <el-col>
                    <div class="d-flex align-items-center">
                        <div class="protocol">
                            {{ penalty.protocol }}
                        </div>
                        <!--                        <div class="status ml-2 pl-1 pr-1 d-flex align-items-center">-->
                        <!--                            Пільга відсутня-->
                        <!--                            <i class="icon exclamation-circle-fill"></i>-->
                        <!--                        </div>-->
                        <div class="status paid ml-2 pl-1 pr-1" v-if="penalty.dpaid">
                                                <span>
                                                    Сплачено
                                                </span>
                        </div>
                        <div class="status wait-confirm ml-2 pl-1 pr-1"
                             v-else-if="penalty.status === 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ'">
                                                <span>
                                                    Очікує підтвердження
                                                </span>
                        </div>
                        <div class="status privilege ml-2 pl-1 pr-1"
                             v-else-if="penalty.isDiscount">
                                                <span>
                                                    Пільга на сплату
                                                    <i class="icon exclamation-circle"></i>
                                                </span>
                        </div>
                        <div class="status ml-2 pl-1 pr-1" v-else>
                                                <span>
                                                    Пільга відсутня
                                                    <i class="icon exclamation-circle"></i>
                                                </span>
                        </div>
                    </div>
                </el-col>
                <el-col>

                </el-col>
                <el-col>
                    <el-button
                        v-if="!penalty.payment || penalty.payment.status !== 'success'"
                        type="primary"
                        class="w-100"
                        @click="$bvModal.show(`modal-pay-${penalty.id}`)"
                    >Сплатити
                    </el-button>
                </el-col>
            </el-row>
            <el-row type="flex" align="middle" class="head">
                <el-col class="text-center p-2">Загальна інформація</el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="mb-2">
                        <b>Постанова</b>
                    </div>
                    <div>
                        {{ penalty.protocol }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Дата постанови</b>
                    </div>
                    <div>{{ penalty.dsignPost }}</div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Сума штрафу</b>
                    </div>
                    <div>
                        {{ parseFloat(penalty.sumPenalty) }} грн
                    </div>
                </el-col>
            </el-row>
            <el-row class="head" v-if="penalty.client">
                <el-col class="text-center p-2">Клієнт</el-col>
            </el-row>
            <el-row type="flex" v-if="penalty.client">
                <el-col>
                    <div class="mb-2">
                        <b>ПІБ</b>
                    </div>
                    <div>
                        {{ penalty.client.fullName }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Телефон</b>
                    </div>
                    <div>
                        {{ penalty.client.phone }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Email</b>
                    </div>
                    <div>
                        {{ penalty.client.email }}
                    </div>
                </el-col>
            </el-row>
            <el-row type="flex" v-if="penalty.client">
                <el-col>
                    <div class="mb-2">
                        <b>Документ, що засвідчує особу</b>
                    </div>
                    <div>
                        {{ penalty.client.document.type }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Серія</b>
                    </div>
                    <div>
                        {{ penalty.client.document.serial }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Номер</b>
                    </div>
                    <div>
                        {{ penalty.client.document.number }}
                    </div>
                </el-col>
            </el-row>
            <el-row class="head">
                <el-col class="text-center p-2">
                    Транспортний засіб
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="mb-2">
                        <b>Номер авто</b>
                    </div>
                    <div>
                        {{ penalty.car.licensePlate }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Марка</b>
                    </div>
                    <div>
                        {{ penalty.car.brand }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Модель</b>
                    </div>
                    <div></div>
                </el-col>
            </el-row>
            <el-row class="head">
                <el-col class="text-center p-2">
                    Деталі правопорушення
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="mb-2">
                        <b>Місце вчинення</b>
                    </div>
                    <div>
                        {{ penalty.region }},
                        {{ penalty.district }},
                        {{ penalty.city }},
                        {{ penalty.street }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Дата правопорушення</b>
                    </div>
                    <div>{{ penalty.dperpetration }}</div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Стаття</b>
                    </div>
                    <div>
                        {{ penalty.pdd }}
                    </div>
                </el-col>
            </el-row>
            <el-row class="head">
                <el-col class="text-center p-2">
                    Платежі
                </el-col>
            </el-row>
            <el-row type="flex" class="payment">
                <el-col :span="6" :xs="24">
                    <el-row type="flex" align="middle" class="mb-0">
                        <el-col>
                            <div>
                                <b>Cума до сплати:</b>
                            </div>
                        </el-col>
                        <el-col>
                            <div class="sum-value">
                                {{ penalty.sumPay }} грн
                            </div>
                        </el-col>
                    </el-row>
                    <el-row type="flex" align="middle" class="mt-0">
                        <el-col>
                            <div class="d-flex">
                                <div v-if="penalty.payment && penalty.payment.status === 'success'"
                                     class="status paid pl-1 pr-1">
                                    Успішна оплата
                                </div>
                                <div v-else class="status pl-1 pr-1">
                                    Не сплачено
                                </div>
                            </div>
                        </el-col>
                        <el-col></el-col>
                    </el-row>
                    <el-row type="flex" align="middle">
                        <el-col>
                            <b>Сума штрафу:</b>
                        </el-col>
                        <el-col>
                            <span class="sum-penalty">
                                {{ penalty.sumPenalty }} грн
                            </span>
                        </el-col>
                    </el-row>
                    <el-row type="flex" align="middle">
                        <el-col>
                            <div class="commission-label">
                                Загальна комісія сервісу:
                            </div>
                        </el-col>
                        <el-col>
                            <span class="commission-value">
                                {{ penalty.sumCommission }} грн
                            </span>
                        </el-col>
                    </el-row>
                    <el-row type="flex" align="middle">
                        <el-col>
                            <div class="earnings-label">
                                Ваш заробіток:
                            </div>
                        </el-col>
                        <el-col>
                            <span class="earnings">
                                {{ penalty.earnings }} грн
                            </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="20" :xs="24">
                            <el-button v-if="penalty.payment && penalty.payment.status === 'success'"
                                       type="primary"
                                       class="w-100"
                                       @click="getReceipt(penalty.id)"
                            >
                                Завантажити квитанцію
                            </el-button>
                            <el-button
                                v-else
                                type="primary"
                                class="w-100"
                                @click="$bvModal.show(`modal-pay-${penalty.id}`)"
                            >Сплатити
                            </el-button>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <modal-pay :penalty="penalty"></modal-pay>
        </div>
    </div>
</template>

<style scoped>
.penalty {
    [type=button]:not(:disabled).el-button--primary {
        background-color: #007BBB;
        border-color: #007BBB;
    }
    .protocol {
        font-size: 25px;
        font-weight: 700;
        line-height: 36.85px;
    }

    .el-row {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .head {
        background: #F6F7FB;
        font-weight: 700;
        font-size: 25px;
        border-radius: 3px;
    }

    .status {
        border-radius: 30px;
        height: 19px;
        color: #ffffff;
        font-size: 14px;
        background: #34393D;
        text-align: center;
    }

    .privilege {
        background: #FFAD3D !important;
    }

    .wait-confirm {
        background: #007BBB !important;
    }

    .paid {
        background: #02A94E !important;
    }

    .payment {
        .el-row {
            margin-bottom: 19px;
        }

        .sum-penalty {
            color: #24282C;
            font-size: 30px;
            font-weight: 700;
            line-height: 44.22px;
        }

        .earnings-label {
            color: #999DA6;
            font-size: 15px;
            font-weight: 700;
        }

        .earnings {
            color: #50A64D;
            font-size: 20px;
            font-weight: 700;
        }

        .commission-label {
            color: #999DA6;
            font-size: 15px;
            font-weight: 700;
        }

        .commission-value {
            color: #24282C;
            font-size: 20px;
            font-weight: 700;
        }

        .sum-value {
            color: #FA9B13;
            font-size: 30px;
            font-weight: 700;
            line-height: 44.22px;
        }
    }
}
</style>
